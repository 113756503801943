$(document).ready(function(){
   
    // $("#toggle").click(function() {
    //     $(this).toggleClass("on");
    //     $(".menu-links").toggleClass("side-slide")
    //   });

      
// $('.company-slider').slick({
//   centerMode: true,
//   infinite: true,
//   slidesToShow: 2,
//   speed: 500,
//   variableWidth: true,
// });



	

});

$(function() {
  $(".btn.down").click(function(){
    $(this).toggleClass("transform");
    if ($(".btn.down").hasClass("transform")) {
      $(".btn.down").removeClass("transform");
       $(this).addClass("transform");
   
    } 
  });
});


$('.company-slider').slick({
  centerMode: true,
  centerPadding: '140px',
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '100px',
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerPadding: '102px',
        arrows: false,
        centerMode: true,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 375,
      settings: {
        centerPadding: '10px',
        arrows: false,
        centerMode: true,
        slidesToShow: 1
      }
    }
  ]
});


$(document).ready(function () {
	$('.top-header .back-new').click(function(){
		$('main').toggleClass('margin-main')
		$('.property.side-nav').toggleClass('full-width')
		$('.top-header .back-new').toggleClass('trans')
	})

});


// ------------step-wizard-------------
$(document).ready(function () {
  $('.nav-tabs > li a[title]').tooltip();
  
  //Wizard
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {

      var target = $(e.target);
  
      if (target.parent().hasClass('disabled')) {
          return false;
      }
  });

  $(".next-step").click(function (e) {

      var active = $('.wizard .nav-tabs li.active');
      active.next().removeClass('disabled');
      nextTab(active);

  });
  $(".prev-step").click(function (e) {

      var active = $('.wizard .nav-tabs li.active');
      prevTab(active);

  });
});

function nextTab(elem) {
  $(elem).next().find('a[data-toggle="tab"]').click();
}
function prevTab(elem) {
  $(elem).prev().find('a[data-toggle="tab"]').click();
}


$('.nav-tabs').on('click', 'li', function() {
  $('.nav-tabs li.active').removeClass('active');
  $(this).addClass('active');
});